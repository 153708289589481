import Button from "@components/Button"
import {
  Colors,
  Text,
  TitleH1,
  TitleH2,
  Iframe,
} from "@components/global.styles"
import * as React from "react"
import { Fragment } from "react"
import { Row } from "shards-react"
import { Containe, Content } from "./styles"

interface IntroductionProps {
  name: string
}

const Main: React.FC<IntroductionProps> = ({ name }) => {
  return (
    <Fragment>
      <Containe>
        <Row>
          <Content>
            <Text style={{ fontSize: "20px" }} colors={Colors.pink}>
              Bine ai venit pe site-ul nostru!
            </Text>
            <TitleH1
              className="op1 big-text"
              style={{ marginTop: "2rem" }}
              colors={Colors.white}
            >
              Planul de Mântuire al lui Dumnezeu
            </TitleH1>
            <TitleH2
              className="medium-text"
              style={{ marginTop: "3rem", marginBottom: "3rem", opacity: 0.7 }}
              colors={Colors.green}
            >
              Grup Marți Seara cu Vasile Lucaci
            </TitleH2>
            <Text className="small-text" colors={Colors.orange}>
              Mai jos gasiti inregistrarile de la grupul de marti!
            </Text>
            <br />
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Iframe
                colors={Colors.white}
                src="https://drive.google.com/embeddedfolderview?id=1rgTTbZhTjoaLVULQ7iIm5fL6vI4SMomQ#list?folderViewSortKey=15"
                style={{ height: "400px", border: "0" }}
              ></Iframe>
            </div>
          </Content>
        </Row>
      </Containe>
    </Fragment>
  )
}

export default Main
